<template>
    <div class="project_check_list">
        <el-card>
            <!-- <el-page-header :content="detail.title" style="margin-bottom: 30px;" @back="goBack" /> -->
            <nav class="input" style="width: auto">
                <el-input v-model="search.keyword" size="medium" placeholder="标题或活动名称" />
            </nav>
            <nav class="input" style="width: 150px">
                <el-select v-model="search.close" size="medium" placeholder="开启状态">
                    <el-option v-for="(item, index) in closeValue" :key="index" :label="item" :value="index">
                    </el-option>
                </el-select>
            </nav>
            <nav class="input">
                <el-button size="small" @click="reset">重置</el-button>
                <el-button type="primary" size="small" @click="subimt">{{ btn ? "搜索" : "loading.." }}</el-button>
            </nav>
            <nav class="input" style="width: auto">
                <!-- <el-button size="small" type="primary" icon="el-icon-plus" @click="add()">添加演讲资料</el-button> -->
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <z-table ref="table" v-model="list" @query="queryList" :storage.sync="search" fullscreenLoading>
                <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                    <el-table-column prop="id" label="ID"> </el-table-column>
                    <el-table-column prop="activityTitle" label="活动名称"></el-table-column>
                    <el-table-column prop="typeName" label="主图">
                        <template #default="{row}">
                            <p-image :src="row.banner" auto> </p-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" label="标题"> </el-table-column>
                    <el-table-column prop="sort" label="排序">
                        <template #default="{ row }">
                            <Remark
                                rowKey="orderNum"
                                :row="row"
                                url="/admin/ActivityPpt/configOrderNum"
                                @getlist="getlist"
                            >
                            </Remark>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="状态">
                        <template #default="{row}">
                            <el-tag size="small" v-if="row.close == 0" type="success"> 开启 </el-tag>
                            <el-tag size="small" v-else type="danger"> 关闭 </el-tag>
                            <div style="margin: 5px;">
                                <el-tag size="small" v-if="row.top == 1" type="success"> 推荐 </el-tag>
                                <el-tag size="small" v-else type="danger"> 不推荐 </el-tag>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="createTime" label="创建时间"> </el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="{ row }">
                            <el-button
                                type="text"
                                icon="el-icon-edit"
                                size="mini"
                                style="color: blue;margin: 3px;"
                                @click="add(row)"
                            >
                                编辑</el-button
                            >
                            <el-popconfirm @confirm="onClose(row)" title="是否继续操作" style="margin: 5px;">
                                <el-button slot="reference" type="text" icon="el-icon-circle-close" size="mini">{{
                                    row.close == 0 ? "关闭" : "开启"
                                }}</el-button>
                            </el-popconfirm>
                            <el-popconfirm
                                @confirm="hot(row, row.top ? 0 : 1)"
                                title="是否继续操作"
                                style="margin: 5px;"
                            >
                                <el-button slot="reference" type="text" icon="el-icon-share" size="mini" class="a5">{{
                                    row.top ? "不推荐" : "推荐"
                                }}</el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </z-table>
        </el-card>
        <Setting ref="Setting" :title="detail.title" :getConfigDetail="getConfigDetail"></Setting>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
import Setting from "../activity_speaker_ppt/setting.vue";
export default {
    mixins: [list],
    data() {
        return {
            search: {
                keyword: "",
                close: "",
            },
            detail: {
                title: "加载中...",
            },
            btn: true,
            configDetail: {},
            closeValue: {
                "-1": "全部",
                0: "开启",
                1: "关闭",
            },
        };
    },

    activated() {
        // console.log(1);
    },
    components: {
        Setting,
    },
    created() {
        // this.search.activityId = this.$route.query.id;
        this.init();
    },
    methods: {
        getlist() {
            this.$refs.table.refresh();
        },
        async openEntrance(done) {
            let { data: res } = await this.$http.post("/admin/ActivityPpt/close", {
                id: this.$route.query.id,
                close: this.configDetail.close == 1 ? 0 : 1,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                await this.getConfigDetail();
                done();
            }
        },
        async hot({ id }, top) {
            await this.$http.post("/admin/ActivityPpt/configTop", {
                id,
                top,
            });
            this.getlist();
        },
        async init() {
            // await this.getDetail();
            // this.getConfigDetail();
        },
        setUp() {
            this.$refs.Setting.init();
        },
        add({ activityId: id }) {
            this.$router.push({ name: "activity_speaker_ppt", query: { id } });
        },
        async subimt() {
            this.btn = false;
            await this.$refs.table.refresh();
            this.btn = true;
        },
        async getDetail() {
            let { data } = await this.$http.get("/admin/ActivityPpt/getList");
            this.detail = data.data;
        },
        getConfigDetail() {
            return new Promise(async (resolve, reject) => {
                try {
                    let {
                        data: { data },
                    } = await this.$http.get("/admin/ActivityPpt/getConfigDetail", {
                        params: { id: this.$route.query.id },
                    });
                    this.configDetail = {
                        ...data,
                        title: data.title || this.detail.title,
                        desc: data.desc || this.detail.desc,
                        banner: data.banner || this.detail.banner,
                    };
                    resolve(this.configDetail);
                } catch (error) {
                    reject(error);
                }
            });
        },
        async onClose({ activityId: id, close }) {
            await this.$http.post("/admin/ActivityPpt/close", { id, close: close ? 0 : 1 });
            this.getlist();
        },
        async queryList(val) {
            const params = { ...val, ...this.search };
            if (params.close === "") params.close = -2;
            let { data: res } = await this.$http.get("/admin/ActivityPpt/getConfigList", {
                params,
            });
            if (res.errorCode == 200) {
                // this.$message.success(res.message);
                this.$refs.table.complete(res.data);
            }
        },
        // async onDelete(row) {
        //     await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
        //         confirmButtonText: "确定",
        //         cancelButtonText: "取消",
        //         type: "warning",
        //     });
        //     let { data: res } = await this.$http.post("/admin/ActivityPpt/del", { id: row.id });
        //     if (res.errorCode == 200) {
        //         this.$message.success(res.message);
        //         this.getlist();
        //     }
        // },

        async viewRecords(done, row) {
            done();
            this.$router.push({
                name: "activity_speaker_ppt_log",
                query: {
                    id: row.id,
                },
            });
            // let { data: res } = await this.$http.post("/admin/ActivityPpt/exportExcel", {
            //     id: row.id,
            // });

            // if (res.errorCode == 200) {
            //     // this.$message.success(res.message);
            //     location.href = res.data.url;
            // }
        },
    },
};
</script>

<style lang="less" scoped>
.tag {
    display: inline-block;
    margin: 5px;
}
</style>
