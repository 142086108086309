var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project_check_list"},[_c('el-card',[_c('nav',{staticClass:"input",staticStyle:{"width":"auto"}},[_c('el-input',{attrs:{"size":"medium","placeholder":"标题或活动名称"},model:{value:(_vm.search.keyword),callback:function ($$v) {_vm.$set(_vm.search, "keyword", $$v)},expression:"search.keyword"}})],1),_c('nav',{staticClass:"input",staticStyle:{"width":"150px"}},[_c('el-select',{attrs:{"size":"medium","placeholder":"开启状态"},model:{value:(_vm.search.close),callback:function ($$v) {_vm.$set(_vm.search, "close", $$v)},expression:"search.close"}},_vm._l((_vm.closeValue),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":index}})}),1)],1),_c('nav',{staticClass:"input"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.reset}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.subimt}},[_vm._v(_vm._s(_vm.btn ? "搜索" : "loading.."))])],1),_c('nav',{staticClass:"input",staticStyle:{"width":"auto"}})]),_c('el-card',{staticStyle:{"margin-top":"10px"}},[_c('z-table',{ref:"table",attrs:{"storage":_vm.search,"fullscreenLoading":""},on:{"query":_vm.queryList,"update:storage":function($event){_vm.search=$event}},model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","stripe":"","header-cell-style":_vm.rowClass}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID"}}),_c('el-table-column',{attrs:{"prop":"activityTitle","label":"活动名称"}}),_c('el-table-column',{attrs:{"prop":"typeName","label":"主图"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p-image',{attrs:{"src":row.banner,"auto":""}})]}}])}),_c('el-table-column',{attrs:{"prop":"title","label":"标题"}}),_c('el-table-column',{attrs:{"prop":"sort","label":"排序"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('Remark',{attrs:{"rowKey":"orderNum","row":row,"url":"/admin/ActivityPpt/configOrderNum"},on:{"getlist":_vm.getlist}})]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.close == 0)?_c('el-tag',{attrs:{"size":"small","type":"success"}},[_vm._v(" 开启 ")]):_c('el-tag',{attrs:{"size":"small","type":"danger"}},[_vm._v(" 关闭 ")]),_c('div',{staticStyle:{"margin":"5px"}},[(row.top == 1)?_c('el-tag',{attrs:{"size":"small","type":"success"}},[_vm._v(" 推荐 ")]):_c('el-tag',{attrs:{"size":"small","type":"danger"}},[_vm._v(" 不推荐 ")])],1)]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间"}}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticStyle:{"color":"blue","margin":"3px"},attrs:{"type":"text","icon":"el-icon-edit","size":"mini"},on:{"click":function($event){return _vm.add(row)}}},[_vm._v(" 编辑")]),_c('el-popconfirm',{staticStyle:{"margin":"5px"},attrs:{"title":"是否继续操作"},on:{"confirm":function($event){return _vm.onClose(row)}}},[_c('el-button',{attrs:{"slot":"reference","type":"text","icon":"el-icon-circle-close","size":"mini"},slot:"reference"},[_vm._v(_vm._s(row.close == 0 ? "关闭" : "开启"))])],1),_c('el-popconfirm',{staticStyle:{"margin":"5px"},attrs:{"title":"是否继续操作"},on:{"confirm":function($event){return _vm.hot(row, row.top ? 0 : 1)}}},[_c('el-button',{staticClass:"a5",attrs:{"slot":"reference","type":"text","icon":"el-icon-share","size":"mini"},slot:"reference"},[_vm._v(_vm._s(row.top ? "不推荐" : "推荐"))])],1)]}}])})],1)],1)],1),_c('Setting',{ref:"Setting",attrs:{"title":_vm.detail.title,"getConfigDetail":_vm.getConfigDetail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }